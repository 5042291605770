import axios from 'axios';
// config
import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  dashboard: {
    getStats: '/dashboard/getStats',
  },
  reports: {
    getPaymentReport: '/reports/getPaymentReport',
    getPaymentReportByRange: '/reports/getPaymentReportByRange',
    getPaymentReportByMonthly: '/reports/getPaymentReportByMonthly',
    getPaymentReportByAnnually: '/reports/getPaymentReportByAnnually',
  },
  auth: {
    me: '/user/getProfile',
    login: '/user/login',
    customerLogin: '/user/customerLogin',
    register: '/api/auth/register',
    resetPassword: '/user/reset-password',
    resetUserPassword: '/user/resetPassword',
    getUserBySecretId: '/user/getUserBySecretId',
  },
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  product: {
    list: '/product/list',
    add: '/product/add',
    delete: '/product/delete',
    edit: '/product/update',
  },
  customer: {
    add: '/customers/add',
    list: '/customers/list',
    delete: '/customers/delete',
    edit: '/customers/update',
  },
  category: {
    list: '/category/list',
    add: '/category/add',
    update: '/category/update',
    delete: '/category/delete',
  },
  subcategory: {
    list: '/subcategory/list',
    add: '/subcategory/add',
    update: '/subcategory/update',
    delete: '/subcategory/delete',
  },
  subscription: {
    list: '/subscription/list',
    add: '/subscription/add',
    update: '/subscription/update',
    delete: '/subscription/delete',
  },
  sales: {
    list: '/sales/list',
    add: '/sales/add',
    update: '/sales/update',
    delete: '/sales/delete',
  },
  distributor: {
    add: '/distributor/add',
    list: '/distributor/list',
    delete: '/distributor/delete',
    edit: '/distributor/update',
  },
  technician: {
    add: '/technician/add',
    list: '/technician/list',
    delete: '/technician/delete',
    edit: '/technician/update',
    taskList: '/technician/tasks/list',
    getTaskDetails: '/technician/tasks/taskDetails',
    updateTask: '/technician/tasks/update',
  },
  order: {
    add: '/order/add',
    list: '/order/list',
    delete: '/order/delete',
    edit: '/order/update',
    assignTechnician: '/order/assignTechnician',
    getAssignedTasks: '/order/getAssignedTasks',
    generateInvoiceNumber: '/order/generateInvoiceNumber',
    forceCloseEmi: '/order/forceCloseEmi',
    updateEmiStatus: '/order/update-emi-status',
    generateAdvancePaymentInvoice: '/order/generateAdvancePaymentInvoice',
    emiUpdate: '/api/orders/update-emi'
  },
  recharge: '/order/recharge',
};
